@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import '../../assets/styles/global/variables';
@import '~bootstrap/scss/mixins';
@import '../../assets/styles/global/mixins';

.section--team-members {
  overflow: hidden;

  .splide {
    &__track {
      overflow: visible !important;
      margin: 90px 0 0;

      @include media-breakpoint-down(xl) {
        margin-top: 60px;
      }

      @include media-breakpoint-down(lg) {
        margin-top: 40px;
      }

      @include media-breakpoint-down(md) {
        margin: 36px 0 40px;
      }

      @include media-breakpoint-down(sm) {
        margin: 24px 0 36px;
      }
    }

    &__arrows {
      width: 8.375rem;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0;

      @include media-breakpoint-down(lg) {
        width: 4.5rem;
      }
    }

    &__arrow {
      background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M38.0287 30.9379C38.4713 30.4953 38.4713 29.7777 38.0287 29.3351L30.8162 22.1226C30.3736 21.68 29.656 21.68 29.2134 22.1226C28.7708 22.5652 28.7708 23.2828 29.2134 23.7254L35.6245 30.1365L29.2134 36.5476C28.7708 36.9902 28.7708 37.7078 29.2134 38.1503C29.656 38.5929 30.3736 38.5929 30.8162 38.1503L38.0287 30.9379ZM22.7727 31.2698L37.2273 31.2698L37.2273 29.0031L22.7727 29.0031L22.7727 31.2698Z' fill='black'/%3E%3Cpath d='M59.25 30C59.25 46.1543 46.1543 59.25 30 59.25C13.8457 59.25 0.75 46.1543 0.75 30C0.75 13.8457 13.8457 0.75 30 0.75C46.1543 0.75 59.25 13.8457 59.25 30Z' stroke='black' stroke-width='1.5'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 3.75rem;
      height: 3.75rem;
      opacity: 1;
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      transform: none;
      background-color: transparent;
      border-radius: 0;
      transition: opacity 0.3s ease-in;

      @include media-breakpoint-down(lg) {
        width: 2rem;
        height: 2rem;
      }

      &[disabled] {
        &.splide__arrow--prev {
          background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.9713 29.0624C21.5287 29.505 21.5287 30.2226 21.9713 30.6652L29.1838 37.8776C29.6264 38.3202 30.344 38.3202 30.7866 37.8776C31.2292 37.4351 31.2292 36.7175 30.7866 36.2749L24.3755 29.8638L30.7866 23.4527C31.2292 23.0101 31.2292 22.2925 30.7866 21.8499C30.344 21.4073 29.6264 21.4073 29.1838 21.8499L21.9713 29.0624ZM37.2273 28.7304L22.7727 28.7304L22.7727 30.9971L37.2273 30.9971L37.2273 28.7304Z' fill='%23516072'/%3E%3Cpath d='M0.750003 30C0.750004 13.8457 13.8457 0.750007 30 0.750009C46.1543 0.75001 59.25 13.8457 59.25 30C59.25 46.1543 46.1543 59.25 30 59.25C13.8457 59.25 0.750001 46.1543 0.750003 30Z' stroke='%23516072' stroke-width='1.5'/%3E%3C/svg%3E%0A");
          transform: rotate(0deg);
        }

        &.splide__arrow--next {
          background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.9713 29.0624C21.5287 29.505 21.5287 30.2226 21.9713 30.6652L29.1838 37.8776C29.6264 38.3202 30.344 38.3202 30.7866 37.8776C31.2292 37.4351 31.2292 36.7175 30.7866 36.2749L24.3755 29.8638L30.7866 23.4527C31.2292 23.0101 31.2292 22.2925 30.7866 21.8499C30.344 21.4073 29.6264 21.4073 29.1838 21.8499L21.9713 29.0624ZM37.2273 28.7304L22.7727 28.7304L22.7727 30.9971L37.2273 30.9971L37.2273 28.7304Z' fill='%23516072'/%3E%3Cpath d='M0.750003 30C0.750004 13.8457 13.8457 0.750007 30 0.750009C46.1543 0.75001 59.25 13.8457 59.25 30C59.25 46.1543 46.1543 59.25 30 59.25C13.8457 59.25 0.750001 46.1543 0.750003 30Z' stroke='%23516072' stroke-width='1.5'/%3E%3C/svg%3E%0A");
          transform: rotate(180deg);
        }
      }

      &--prev {
        transform: rotate(-180deg);
      }
    }
  }

  .team-members {
    &__subtitle {
      font-weight: 600;
    }

    &-item {
      width: 18.75rem;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      @include media-breakpoint-down(sm) {
        width: 164px;
      }

      &__img {
        object-position: 50% 0%;

        &-wrapper {
          padding-bottom: 107%;
        }
      }

      &__content {
        flex: 1 1 100%;
        padding: 25px;
        background-color: $white;

        @include media-breakpoint-down(sm) {
          padding: 12px;
        }
      }

      &__title {
        font-family: 'Poppins';
        font-size: $font-size-base;
        margin: 0 0 6px;

        @include media-breakpoint-down(sm) {
          font-size: $font-size-sm;
          margin: 0 0 3px;
        }
      }

      &__position {
        font-weight: 500;
        font-size: $font-size-sm;
        color: $primary;
        margin: 0;

        @include media-breakpoint-down(sm) {
          font-size: $font-size-base * 0.75;
        }
      }
    }
  }
}

@include color-mode(dark) {
  .section--team-members {
    .btn {
      color: $white;
    }

    .team-members-item {
      &__content {
        background: #516072;
      }

      &__position {
        color: $white;
      }
    }

    .splide__arrow {
      background-image: url('data:image/svg+xml,%3Csvg width=\'32\' height=\'32\' viewBox=\'0 0 32 32\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M20.2788 16.497C20.5131 16.2627 20.5131 15.8828 20.2788 15.6485L16.4604 11.8301C16.2261 11.5958 15.8462 11.5958 15.6119 11.8301C15.3776 12.0644 15.3776 12.4443 15.6119 12.6786L19.006 16.0728L15.6119 19.4669C15.3776 19.7012 15.3776 20.0811 15.6119 20.3154C15.8462 20.5497 16.2261 20.5497 16.4604 20.3154L20.2788 16.497ZM12.1454 16.6728L19.8545 16.6728L19.8545 15.4728L12.1454 15.4728L12.1454 16.6728Z\' fill=\'white\'/%3E%3Cpath d=\'M31.5 16C31.5 24.5604 24.5604 31.5 16 31.5C7.43959 31.5 0.5 24.5604 0.5 16C0.5 7.43959 7.43959 0.5 16 0.5C24.5604 0.5 31.5 7.43959 31.5 16Z\' stroke=\'white\'/%3E%3C/svg%3E%0A');
    }
  }
}
