/*

Overwrite bootstrap variables.
To change the value of a variable, copy it from _variables-bootstrap.scss into here,
removes the '! default' at the end of the line and adjusts the value.

*/

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 769px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 2000px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1336px,
  xxxl: 1600px
);

$grid-gutter-width: 1.25rem; // 1.5rem !default;
$container-padding-x: 2rem; //$grid-gutter-width !default;


$pink:  #d157a3; //#d63384 !default;
$purple: #5d42d6; // #6f42c1 !default;
$indigo: #5a6ff0; // #6610f2 !default;
$teal:  #0fd; //#20c997 !default;

$gradient: linear-gradient(90deg, rgba($indigo, 0.8) -0.8%, $teal 94.9%);
//linear-gradient(180deg, rgba($white, 0.15), rgba($white, 0)) !default;


$white: #fff;
//$gray-100: #f8f9fa !default;
$gray-bg: #dce9f8;

$gray-200: #efefef; //#e9ecef !default;
$gray-300: #d9e1e9; //#dee2e6 !default;
$gray-400: #c0c0c0; //#ced4da !default;
$gray-500: #cad0d6; //#adb5bd !default;
$gray-600: #516072; //#6c757d !default;
$gray-700: #868585; //#495057 !default;
$gray-800: #686868; //#343a40 !default;
$gray-900: #393939; //#212529 !default;
$black: #000;

$primary: $pink;
$secondary: $purple;
$light-blue: #b6c7da;

$body-color: $black; //$gray-900 !default;
$body-bg: $light-blue; // $white !default;

$theme-colors: (
  "primary":  $primary,
  "secondary":  $secondary,
  "light-blue":  $light-blue,
  'black': $black,
  'white': $white,
  'pink': $pink,
  'purple': $purple,
  'indigo': $indigo,
  'teal': $teal,
  'gray-bg' : $gray-bg,
  'gray-200' : $gray-200,
  'gray-300' : $gray-300,
  'gray-400' : $gray-400,
  'gray-500' : $gray-500,
  'gray-600' : $gray-600,
  'gray-700' : $gray-700,
  'gray-800' : $gray-800,
  'gray-900' : $gray-900
);

$border-color: $gray-800; // $gray-300 !default;

$border-radius: 0.5; // 0.375rem !default;
//$border-radius-sm:  0.25rem !default;
$border-radius-lg:  1rem; //0.5rem !default;
$border-radius-xl:  1.25rem; //1rem !default;
$border-radius-2xl:  1.5rem; //2rem !default;
$border-radius-3xl:  1.75rem;
//$border-radius-pill:  50rem !default;

$transition-base: all 0.3s ease-in-out; // all 0.2s ease-in-out !default;

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: ($spacer * 0.25),
  2: ($spacer * 0.5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3.75),
  6: ($spacer * 6),
  7: ($spacer * 7.5)
); //(0: 0, 1: ($spacer * 0.25), 2: ($spacer * 0.5), 3: $spacer, 4: ($spacer * 1.5), 5: ($spacer * 3)) !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: 'Poppins', sans-serif;
$font-family-base: $font-family-sans-serif;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1.125); //1.25) !default;
$font-size-sm: ($font-size-base * 0.875) !default;

$h1-font-size: $font-size-base * 5.25; //2.5 !default; 76px
$h2-font-size: $font-size-base * 3.875; //2 !default; 36px - 2.25 in design
$h3-font-size: $font-size-base * 1.75; //1.75 !default; 28px
$h4-font-size: $font-size-base * 1.5; //1.5 !default; 24px
$h5-font-size: $font-size-base * 0.875; //1.25 !default; 14px
$h6-font-size: $font-size-base * 0.75;  //1 !default; 12px

$headings-font-weight: 500; //500 !default;
$headings-font-family: 'NeueMachina', sans-serif;

$modal-inner-padding: 0; //$spacer !default;
$modal-content-bg: $white;
$modal-content-border-width: 0;
$modal-content-border-radius:  $border-radius-2xl; //$border-radius-lg !default;
$modal-header-border-width: 0;
$modal-header-padding: 0 0;
$modal-lg:  980px; //800px !default;

$enable-smooth-scroll:  false; //true !default;
